import React, { useEffect, useMemo, useState } from "react";
import LogoutIcon from "@mui/icons-material/Logout";
import bullionStats from "../assets/bullioStats.png";
import uaeflag from "../assets/uaeflag.png";
import unflag from "../assets/unflag.png";
import bgimg from "../assets/bgimg.png";
import usaflag from "../assets/usaflag.png";
import indiaflag from "../assets/indiaflag.png";
import dolorpng from "../assets/dolor.png";
import logo from "../assets/logo.png";
import { Box, Fade, LinearProgress, Modal, Typography } from "@mui/material";
import Marquee from "react-fast-marquee";
import "../App.css";
import moment from "moment-timezone";
import io from "socket.io-client";
import {
  allCommodities,
  allNewses,
  getConversionValue,
  getRatio,
  getSocketUrl,
  getSpread,
} from "../sevice/home";
import { loginChecking, logoutTv } from "../sevice/login";
import Cookies from "js-cookie";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import LimitExceededModal from "./LimitExceededModal";
import SubscriptionExpiringSoon from "./SubscriptionExpiringSoon";
import SubscriptionExpired from "./SubscriptionExpired";
const { makeStyles } = require("@mui/styles");
let socket = Cookies.get("socketUrl")
  ? io(JSON.parse(Cookies.get("socketUrl")))
  : null;

const socket2 = io(process.env.REACT_APP_BACKEND_URL);
const adminId = process.env.REACT_APP_ADMIN_ID;

const useStyles = makeStyles((theme) => ({
  mainBody: {
    height: "100Vh",
    display: "flex",
    flexDirection: "column",
  },
  mainContainer: {
    width: "100%",
    height: "95vh",
    margin: "auto",
    display: "grid",
    gridTemplateColumns: "1.1fr 2fr",
    gridTemplateRows: "1fr",
    gridTemplateAreas: `
        'leftPart rightPart'
      `,
    padding: "2rem 2rem 2rem 2rem ",
    boxSizing: "border-box",
    columnGap: "2rem",
  },
  fontgoldColor: {
    backgroundImage: ` linear-gradient(90deg, #C09445 1.19%, #EED770 37.04%, #C59C4A 76.42%, #F4E076 98.13%)`,
    WebkitBackgroundClip: "text", // For Safari
    backgroundClip: "text",
    color: "transparent",
  },
  leftPart: {
    boxSizing: "border-box",
    gridArea: "leftPart",
    width: "100%",
    height: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
  },
  currency: {
    height: "32%",
    boxSizing: "border-box",
    // display: "flex",
    justifyContent: "center",
    alignItems: "center",
    display: "grid",
    gridTemplateColumns: "35% 30% 35%",
    backgroundColor: "#115449",
    borderRadius: "10px",
    padding: "1.3rem",
  },
  currencyCol: {
    backgroundColor: "#04372F",
    borderRadius: "10px",
    display: "flex",
  },
  currencyTextBox: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    color: "#FFFFFF",
  },
  spotRate: {
    height: "53%",
    boxSizing: "border-box",
    display: "flex",
    flexDirection: "column",
    color: "#16341B",
    gap: ".6rem",
    color: "#FFFFFF",
  },
  spotRateBoxGoldRow2Col1: {
    gridArea: "spotRateBoxGoldRow2Col1",
    display: "flex",
    flexDirection: "column",
    gap: "0.3rem",
    alignItems: "center",
    flexBasis: 0,
    flexGrow: 1,
    flexShrink: 1,
  },
  spotRateBoxGoldRow2Col2: {
    gridArea: "spotRateBoxGoldRow2Col2",
    display: "flex",
    flexDirection: "column",
    gap: "0.3rem",
    alignItems: "center",
    flexBasis: 0,
    flexGrow: 1,
    flexShrink: 1,
  },
  spotRateBoxSilverRow2Col1: {
    gridArea: "spotRateBoxSilverRow2Col1",
    display: "flex",
    flexDirection: "column",
    gap: "0.3rem",
    alignItems: "center",
    flexBasis: 0,
    flexGrow: 1,
    flexShrink: 1,
  },
  spotRateBoxSilverRow2Col2: {
    gridArea: "spotRateBoxSilverRow2Col2",
    display: "flex",
    flexDirection: "column",
    gap: "0.3rem",
    alignItems: "center",
    flexBasis: 0,
    flexGrow: 1,
    flexShrink: 1,
  },

  stat: {
    height: "12%",
    gridArea: "stat",
    color: "#fff",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: "#115449",
    borderRadius: "8px",
  },
  bullionStatsImg: {
    width: "7vw",
    height: "auto",
  },
  rightPart: {
    gridArea: "rightPart",
    width: "100%",
    height: "100%",
    display: "flex",
    justifyContent: "space-between",
    boxSizing: "border-box",
    flexDirection: "column",
  },
  header: {
    height: "32.5%",
    display: "flex",
  },
  logoImg: {
    width: "100%",
    alignItems: "center",
    height: "100%",
    objectFit: "contain",
  },

  commoditieTable: {
    gridArea: "commoditieTable",
    height: "65%",
    display: "flex",
    boxSizing: "border-box",
    flexDirection: "column",
    borderRadius: "5px",
  },
  table: {
    gridArea: "table",
    gap: "10px",
    boxSizing: "border-box",
    color: "#FFFFFF",
    display: "flex",
    flexDirection: "column",
    height: "100%",
  },
  tabeHeader: {
    gridArea: "tabeHeader",
    flex: "1",
    display: "flex",
    justifyContent: "center",
    borderRadius: "5px",
    alignItems: "center",
    height: "20%",
    border: "6px solid #115449",
  },
  tableContent: {
    gridArea: "tableContent",
    display: "flex",
    height: "80%",
    flexDirection: "column",
    gap: "10px",
    overflowX: "scroll",
    overflowX: "hidden",
    "&::-webkit-scrollbar": {
      display: "none",
    },
  },
  tableRowColumn: {
    flex: "1",
    display: "flex",
    alignItems: "center",
    color: "#FFFFFF",
    // justifyContent: "center",
    fontSize: "2vw",
  },
  tableRow1: {
    flexBasis: 0,
    minHeight: "18%",
    flexGrow: 1,
    gridArea: "tableRow1",
    borderRadius: "5px",
    background: "#115449",
    display: "flex",
  },

  updates: {
    padding: "0rem 0rem 1.5rem 0rem",
    color: "#fff",
    display: "flex",
    height: "7%",
    color: "#FFFFFF",
  },

  updatesContent: {
    display: "flex",
    alignItems: "center",
    background: "#11544980",
    width: "100%",
    borderRadius: "0px 5px 5px 0px",
  },

  blur: {
    filter: "blur(8px)",
  },
}));

const Home = () => {
  let navigate = useNavigate();
  const dispatch = useDispatch();
  const { tv } = useSelector((state) => ({ ...state }));
  const classes = useStyles();

  const [news, setNews] = useState([]);
  const [commodities, setCommodities] = useState([]);
  const [isMarketOpen, setIsMarketOpen] = useState(true);
  const [remainingTime, setRemainingTime] = useState(null);
  const [openlimitExpierd, setOpenlimitExpierd] = useState(false);
  const [openSubscription, setOpenSubscription] = useState(false);
  const [openSubscriptionExpierd, setOpenSubscriptionExpierd] = useState(false);
  const [time, setTime] = useState(new Date());
  const [currency, setCurrency] = useState(0);
  const [spread, setSpread] = useState({
    goldAskSpread: 0,
    goldBidSpread: 0,
    silverAskSpread: 0,
    silverBidSpread: 0,
    goldLowSpread: 0,
    goldHighSpread: 0,
    silverLowSpread: 0,
    silverHighSpread: 0,
  });

  const [ratio, setRatio] = useState({
    Buyers: "77%",
    chgValue: "+0.370%",
    commodity: "Gold",
  });

  const [gold, setGold] = useState({
    cur: { ask: 0, bid: 0, highPrice: 0, lowPrice: 0 },
    pre: { ask: 0, bid: 0, highPrice: 0, lowPrice: 0 },
  });
  const [silver, setSilver] = useState({
    cur: { ask: 0, bid: 0, highPrice: 0, lowPrice: 0 },
    pre: { ask: 0, bid: 0, highPrice: 0, lowPrice: 0 },
  });
  const [render, setRender] = useState(true);

  useMemo(() => {
    if (socket && render) {
      setRender(false);
      socket.on("connect", () => {});
      socket.on("disconnect", () => {});
      socket.on("connect_error", (err) => {
        console.log(`connect_error due to ${err.message}`);
      });
      socket.on("gold-rate-change", (data) => {
        setIsMarketOpen(data.data.isMarketOpen);
        setGold((e) => {
          return { cur: data.data, pre: e.cur };
        });
      });
      socket.on("silver-rate-change", (data) => {
        setSilver((e) => {
          return { cur: data.data, pre: e.cur };
        });
      });
    }
  }, [socket]);

  useEffect(() => {
    let timerId;
    if (!isMarketOpen) {
      timerId = setInterval(() => {
        let currentTime = new Date();
        let nextMonday = new Date();
        let dubaiTime = moment(Date.now()).tz("Asia/Dubai");
        nextMonday.setFullYear(
          Number(dubaiTime.format("YYYY")),
          Number(dubaiTime.format("MM")) - 1,
          Number(dubaiTime.format("D"))
        );
        currentTime.setFullYear(
          Number(dubaiTime.format("YYYY")),
          Number(dubaiTime.format("MM")) - 1,
          Number(dubaiTime.format("D"))
        );
        currentTime.setHours(
          dubaiTime.format("H"),
          dubaiTime.format("mm"),
          dubaiTime.format("ss")
        );

        if (dubaiTime.format("ddd") !== "Mon") {
          nextMonday.setDate(
            nextMonday.getDate() + ((1 + 7 - nextMonday.getDay()) % 7 || 7)
          );
        }
        nextMonday.setHours(1, 59, 0, 0);
        const elapsedTime = nextMonday - currentTime;
        if (elapsedTime <= 0) {
          clearInterval(timerId);
        } else {
          setRemainingTime(elapsedTime);
        }
      }, 1000);
    }
    return () => clearInterval(timerId); // Clear the timer when the component unmounts or when startTimer becomes false
  }, [isMarketOpen]);

  const formatRemainingTime = (time) => {
    const seconds = Math.floor((time / 1000) % 60);
    const minutes = Math.floor((time / 1000 / 60) % 60);
    const hours = Math.floor((time / 1000 / 60 / 60) % 24);
    const days = Math.floor(time / 1000 / 60 / 60 / 24);

    return `${days}d ${hours}h ${minutes}m ${seconds}s`;
  };

  const commodityCalculation = (
    livePrice,
    spread,
    premium,
    fxRate,
    purity,
    unit,
    weight,
    charge
  ) => {
    let value =
      (Number(livePrice) + Number(spread) + Number(premium)) *
        (fxRate / 31.1035) *
        Number(purity) *
        Number(weight) *
        Number(unit) +
      Number(charge);
    if (value.toFixed(0).length < 5) {
      return value.toLocaleString("en-US", {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      });
    } else {
      return value.toLocaleString("en-US", {
        // minimumFractionDigits: 2,
        maximumFractionDigits: 0,
      });
    }
  };

  useEffect(() => {
    handleFindRatio();
  }, []);

  useEffect(() => {
    hadlefetchNews();
  }, []);

  useEffect(() => {
    handleFindSpread();
  }, []);

  useEffect(() => {
    handleFinsCommodities();
  }, []);

  useEffect(() => {
    handleloginChecking();
  }, []);

  useEffect(() => {
    const timer = setInterval(() => {
      setTime(new Date());
    }, 1000);

    return () => {
      clearInterval(timer);
    };
  }, []);

  useEffect(() => {
    socket2.on("connect", () => {});
    socket2.on("disconnect", () => {});
    socket2.emit("join_room", { roomId: adminId });
    socket2.on("commodityRerender", (data) => {
      handleFinsCommodities();
    });
    socket2.on("newsRerender", (data) => {
      hadlefetchNews();
    });
    socket2.on("spreadRerender", (data) => {
      handleFindSpread();
    });
    socket2.on("loginChecking", (data) => {
      handleloginChecking();
    });
    socket2.on("adminBlockRerender", (data) => {
      handleLogout();
    });
    socket2.on("socketRerender", (data) => {
      Cookies.remove("socketUrl");
      window.location.reload();
    });
  }, []);

  useEffect(() => {
    handleFindCurrency();
  }, []);

  useEffect(() => {
    const interval = setInterval(() => {
      triggerExpire();
    }, 60 * 60 * 1000);
    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    handlecheckSubscriptionExpierd();
  }, []);

  useEffect(() => {
    handleFindSocketUrl();
  }, []);

  const handleFindSocketUrl = async () => {
    const res = await getSocketUrl();
    if (res) {
      socket = io(res?.socketURL);
      Cookies.set("socketUrl", JSON.stringify(res?.socketURL));
    }
  };

  const handleFindCurrency = async () => {
    const res = await getConversionValue("USD");
    if (res) {
      setCurrency(res);
    }
  };

  const progress = Number(ratio?.Buyers?.match(/\d+/)[0]);

  const buyersColor = "#2FB545";
  const sellersColor = "#D1172D";

  const progressStyle = {
    backgroundColor: sellersColor,
  };

  const barStyle = {
    backgroundColor: buyersColor,
  };

  const formatTime = (date) => {
    return date.toLocaleTimeString([], { hour: "2-digit", minute: "2-digit" });
  };

  const formatDate = () => {
    return moment(new Date()).format("DD MMM YYYY");
  };

  const formatday = () => {
    return moment(new Date()).format("dddd");
  };

  const handleloginChecking = async () => {
    const deviceId = Cookies.get("deviceId")
      ? JSON.parse(Cookies.get("deviceId"))
      : null;
    const res = await loginChecking(deviceId);
    console.log(res);
    if (res.status === false && res?.totalLoginCount <= res?.liveCounts) {
      setOpenlimitExpierd(true);
    } else if (res.status === false) {
      Cookies.remove("tv");
      Cookies.remove("deviceId");
      Cookies.remove("socketUrl");
      Cookies.remove("socketUrl");
      window.location.reload();
      dispatch({
        type: "LOGOUT",
      });
      navigate("/login");
    }
  };

  const handleOpen = () => setOpenSubscription(true);
  const handleClose = () => setOpenSubscription(false);
  const handleOpenSubscriptionExpierd = () => setOpenSubscriptionExpierd(true);
  const handleCloseSubscriptionExpierd = () =>
    setOpenSubscriptionExpierd(false);

  const handleFinsCommodities = async () => {
    const res = await allCommodities();
    if (Array.isArray(res.commodities)) {
      setCommodities(res.commodities);
    }
  };

  const handleFindSpread = async () => {
    const res = await getSpread();
    if (res) {
      setSpread(res);
    }
  };

  const handleFindRatio = async () => {
    const res = await getRatio();
    if (res?.data) {
      setRatio(res.data);
    }
  };

  const hadlefetchNews = async () => {
    const res = await allNewses();
    if (Array.isArray(res)) {
      setNews(res);
    }
  };

  async function triggerExpire() {
    const givenDate = new Date(tv?.package.expire_date);
    const oneMonthAgo = new Date(givenDate);
    oneMonthAgo.setDate(givenDate.getDate() - 30);
    let a = oneMonthAgo.toISOString();
    let b = new Date().toISOString();

    if (b >= a) {
      const currentDate = new Date();
      const currentHour = currentDate.getHours();
      if (currentHour === 10) {
        handleOpen();
      }
    }
  }

  const handlecheckSubscriptionExpierd = () => {
    const givenDate = new Date(tv?.package.expire_date);
    let a = givenDate.toISOString();
    let b = new Date().toISOString();
    if (a < b) {
      handleOpenSubscriptionExpierd();
    }
  };
  const handleLogout = async () => {
    const deviceId = Cookies.get("deviceId")
      ? JSON.parse(Cookies.get("deviceId"))
      : null;
    const res = await logoutTv({
      adminId: adminId,
      deviceId: deviceId,
    });
    if (res) {
      Cookies.remove("tv");
      Cookies.remove("deviceId");
      Cookies.remove("socketUrl");
      window.location.reload();
      dispatch({
        type: "LOGOUT",
      });
      navigate("/login");
    }
  };
  return (
    <div
      style={{
        backgroundImage: `url(${bgimg})`,
        width: "100dvw",
        height: "100dvh",
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
        backgroundPosition: "center",
      }}
      className={(openlimitExpierd || openSubscriptionExpierd) && classes.blur}
    >
      <Box className={classes.mainBody}>
        <Box className={classes.mainContainer}>
          {!isMarketOpen && (
            <Box
              sx={{
                position: "fixed",
                top: "0",
                left: "-125px",
                right: "0",
                bottom: "0",
                width: "895px",
                height: "531px",
                overflow: "hidden",
                zIndex: "99",
              }}
            >
              <div
                style={{
                  position: "relative",
                  width: "100%",
                  height: "10%",
                  transform: "rotate(-35deg)",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  backgroundColor: "#8C003D",
                  color: "#FFFFFF",
                  fontSize: "2vw",
                  fontWeight: "bold",
                  padding: "20px",
                  lineHeight: 1.5,
                  alignItems: "center",
                  flexDirection: "column",
                  justifyContent: "space-evenly",
                }}
              >
                <Marquee delay="2" gradient={false}>
                  {remainingTime && (
                    <p style={{ marginLeft: "90px" }}>
                      Market closed! Opens on{" "}
                      {formatRemainingTime(remainingTime)}
                    </p>
                  )}
                </Marquee>
              </div>
            </Box>
          )}
          <Box className={classes.leftPart}>
            <Box className={classes.currency}>
              {/* <img
                style={{
                  width: "80%",
                  objectFit: "cover",
                }}
                src={logo_with_name}
                alt="Logo"
              /> */}
              <Box
                sx={{
                  height: "100%",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "space-between",
                }}
              >
                <Box className={classes.currencyCol} sx={{ height: "8vh" }}>
                  <img
                    src={uaeflag}
                    alt="usaflag"
                    style={{ height: "100%", width: "40%", objectFit: "fill" }}
                  />
                  <Box className={classes.currencyTextBox}>
                    <Typography sx={{ fontSize: "1vw" }}>AED</Typography>
                    <Typography
                      sx={{ fontWeight: "bold", fontSize: "1.3vw", mt: -0.5 }}
                    >
                      {currency?.conversion_rates?.AED &&
                        Number(currency?.conversion_rates?.AED).toFixed(2)}
                    </Typography>
                  </Box>
                </Box>
                <Box className={classes.currencyCol} sx={{ height: "8vh" }}>
                  <img
                    src={indiaflag}
                    alt="usaflag"
                    style={{ height: "100%", width: "40%", objectFit: "fill" }}
                  />
                  <Box className={classes.currencyTextBox}>
                    <Typography sx={{ fontSize: "1vw" }}>₹</Typography>
                    <Typography
                      sx={{ fontWeight: "bold", fontSize: "1.3vw", mt: -0.5 }}
                    >
                      {currency?.conversion_rates?.INR &&
                        Number(currency?.conversion_rates?.INR).toFixed(2)}
                    </Typography>
                  </Box>
                </Box>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <img
                  src={dolorpng}
                  alt="dolorpng"
                  style={{ width: "80%" }}
                  srcset=""
                />
              </Box>
              <Box
                sx={{
                  height: "100%",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "space-between",
                }}
              >
                <Box className={classes.currencyCol} sx={{ height: "8vh" }}>
                  <img
                    src={unflag}
                    alt="usaflag"
                    style={{ height: "100%", width: "40%", objectFit: "fill" }}
                  />
                  <Box className={classes.currencyTextBox}>
                    <Typography sx={{ fontSize: "1vw" }}>€</Typography>
                    <Typography
                      sx={{ fontWeight: "bold", fontSize: "1.3vw", mt: -0.5 }}
                    >
                      {currency?.conversion_rates?.EUR &&
                        Number(currency?.conversion_rates?.EUR).toFixed(2)}
                    </Typography>
                  </Box>
                </Box>
                <Box className={classes.currencyCol} sx={{ height: "8vh" }}>
                  <img
                    src={usaflag}
                    alt="usaflag"
                    style={{ height: "100%", width: "40%", objectFit: "fill" }}
                  />
                  <Box className={classes.currencyTextBox}>
                    <Typography sx={{ fontSize: "1vw" }}>€</Typography>
                    <Typography
                      sx={{ fontWeight: "bold", fontSize: "1.3vw", mt: -0.5 }}
                    >
                      {currency?.conversion_rates?.USD &&
                        Number(currency?.conversion_rates?.USD).toFixed(2)}
                    </Typography>
                  </Box>
                </Box>
              </Box>
            </Box>
            <Box className={classes.spotRate}>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  height: "19%",
                  justifyContent: "center",
                  display: "flex",
                  border: "5px solid #115449",
                  borderRadius: "8px",
                }}
              >
                <Typography
                  sx={{
                    fontSize: "1.7VW",
                    letterSpacing: "5px",
                    fontWeight: "bold",
                  }}
                >
                  SPOT RATE
                </Typography>
              </Box>
              <Box
                sx={{
                  height: "39%",
                  display: "flex",
                  alignItems: "center",
                  backgroundColor: "#115449",
                  borderRadius: "8px",
                }}
              >
                <Typography
                  sx={{
                    fontSize: "1.8vw",
                    fontWeight: "bold",
                    p: 0,
                    flexBasis: 0,
                    flexGrow: 0.8,
                    flexShrink: 1,
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  GOLD
                  <br />
                  <Typography
                    sx={{
                      fontSize: "1.8vw",
                      fontWeight: "bold",
                      flexBasis: 0,
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "center",
                      alignItems: "center",
                      marginTop: -1.5,
                    }}
                  >
                    Oz
                  </Typography>
                </Typography>

                <Box className={classes.spotRateBoxGoldRow2Col1}>
                  <Box
                    sx={{
                      backgroundColor:
                        Number(gold?.cur?.bid).toFixed(2) <
                        Number(gold?.pre?.bid).toFixed(2)
                          ? "#D1172D"
                          : Number(gold?.cur?.bid).toFixed(2) >
                            Number(gold?.pre?.bid).toFixed(2)
                          ? "#2FB545"
                          : "",
                      border: "2px solid #FFFFFF",
                      color: "#FFFFFF",
                      borderRadius: "5px",
                      width: "8.3vw",
                      height: "8vh",
                      display: "flex",
                      alignItems: "center",
                      px: 1.5,
                      transition: "background-color .5s ease",
                      justifyContent: "center",
                    }}
                  >
                    <Typography sx={{ fontSize: "2.2vw", fontWeight: "bold" }}>
                      {(
                        Number(spread.goldBidSpread) + Number(gold?.cur?.bid)
                      ).toFixed(2)}
                    </Typography>
                  </Box>

                  <Box
                    sx={{
                      display: "flex",
                      gap: "5px",
                      alignItems: "center",
                    }}
                  >
                    <Typography style={{ fontSize: "1vw", color: "#D1172D" }}>
                      Low
                    </Typography>

                    <Typography style={{ fontSize: "1vw" }}>
                      {(
                        Number(spread.goldLowSpread) +
                        Number(gold?.cur?.lowPrice)
                      ).toFixed(2)}
                    </Typography>
                  </Box>
                </Box>

                <Box className={classes.spotRateBoxGoldRow2Col2}>
                  <Box
                    sx={{
                      backgroundColor:
                        Number(gold?.cur?.ask).toFixed(2) <
                        Number(gold?.pre?.ask).toFixed(2)
                          ? "#D1172D"
                          : Number(gold?.cur?.ask).toFixed(2) >
                            Number(gold?.pre?.ask).toFixed(2)
                          ? "#2FB545"
                          : "",
                      border: "2px solid #FFFFFF",
                      color: "#FFFFFF",
                      borderRadius: "5px",
                      width: "8.3vw",
                      height: "8vh",
                      display: "flex",
                      alignItems: "center",
                      px: 1.5,
                      transition: "background-color .5s ease",
                      justifyContent: "center",
                    }}
                  >
                    <Typography sx={{ fontSize: "2.2vw", fontWeight: "bold" }}>
                      {(
                        Number(spread.goldAskSpread) + Number(gold?.cur?.ask)
                      ).toFixed(2)}
                    </Typography>
                  </Box>

                  <Box
                    sx={{
                      display: "flex",
                      gap: "5px",
                      alignItems: "center",
                    }}
                  >
                    <Typography style={{ fontSize: "1vw", color: "#2FB545" }}>
                      High
                    </Typography>
                    <Typography style={{ fontSize: "1vw" }}>
                      {(
                        Number(spread.goldHighSpread) +
                        Number(gold?.cur?.highPrice)
                      ).toFixed(2)}
                    </Typography>
                  </Box>
                </Box>
              </Box>
              <Box
                sx={{
                  height: "35%",
                  display: "flex",
                  alignItems: "center",
                  backgroundColor: "#115449",
                  borderRadius: "8px",
                }}
              >
                <Typography
                  sx={{
                    fontSize: "1.2vw",
                    flexBasis: 0,
                    flexGrow: 0.8,
                    flexShrink: 1,
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                    mt: -2.5,
                  }}
                >
                  SILVER
                  <Typography
                    sx={{
                      fontSize: "1.2vw",
                      flexBasis: 0,
                      display: "flex",
                      justifyContent: "center",
                      marginTop: -1,
                    }}
                  >
                    Oz
                  </Typography>
                </Typography>

                <Box className={classes.spotRateBoxSilverRow2Col1}>
                  <Box
                    sx={{
                      backgroundColor:
                        Number(silver?.cur?.bid).toFixed(2) <
                        Number(silver?.pre?.bid).toFixed(2)
                          ? "#D1172D"
                          : Number(silver?.cur?.bid).toFixed(2) >
                            Number(silver?.pre?.bid).toFixed(2)
                          ? "#2FB545"
                          : "",
                      border: "2px solid #FFFFFF",
                      color: "#FFFFFF",
                      borderRadius: "5px",
                      px: 1.5,
                      width: "8.3vw",
                      height: "6vh",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      transition: "background-color .5s ease",
                    }}
                  >
                    <Typography sx={{ fontSize: "2vw" }}>
                      &nbsp;&nbsp;
                      {(
                        Number(spread.silverBidSpread) +
                        Number(silver?.cur?.bid)
                      ).toFixed(2)}
                      &nbsp;&nbsp;
                    </Typography>
                  </Box>

                  <Box
                    sx={{
                      display: "flex",
                      gap: "5px",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <Typography sx={{ fontSize: "1vw", mx: "auto" }}>
                      <span style={{ color: "#D1172D" }}>Low &nbsp;</span>
                      {(
                        Number(spread.silverLowSpread) +
                        Number(silver?.cur?.lowPrice)
                      ).toFixed(2)}
                    </Typography>
                  </Box>
                </Box>

                <Box className={classes.spotRateBoxSilverRow2Col2}>
                  <Box
                    sx={{
                      backgroundColor:
                        Number(silver?.cur?.ask).toFixed(2) <
                        Number(silver?.pre?.ask).toFixed(2)
                          ? "#D1172D"
                          : Number(silver?.cur?.ask).toFixed(2) >
                            Number(silver?.pre?.ask).toFixed(2)
                          ? "#2FB545"
                          : "",
                      border: "2px solid #FFFFFF",
                      color: "#FFFFFF",
                      borderRadius: "5px",
                      px: 1.5,
                      width: "8.3vw",
                      height: "6vh",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      transition: "background-color .5s ease",
                    }}
                  >
                    <Typography sx={{ fontSize: "2vw" }}>
                      &nbsp;&nbsp;
                      {(
                        Number(spread.silverAskSpread) +
                        Number(silver?.cur?.ask)
                      ).toFixed(2)}
                      &nbsp;&nbsp;
                    </Typography>
                  </Box>

                  <Box
                    sx={{
                      display: "flex",
                      gap: "5px",
                      alignItems: "center",
                    }}
                  >
                    <Typography style={{ fontSize: "1vw" }}>
                      <span style={{ color: "#2FB545" }}>High &nbsp;</span>
                      {(
                        Number(spread.silverHighSpread) +
                        Number(silver?.cur?.highPrice)
                      ).toFixed(2)}
                    </Typography>
                  </Box>
                </Box>
              </Box>
            </Box>

            <Box className={classes.stat}>
              <>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    width: "100%",
                    px: 5,
                    py: 1,
                    boxSizing: "border-box",
                  }}
                >
                  <Typography sx={{ fontSize: ".9vw" }}>BUYERS</Typography>
                  <Typography
                    sx={{
                      fontSize: ".9vw",
                      color:
                        Number(ratio?.chgValue.replace("%", "")) >= 0
                          ? "#2FB545"
                          : "#D1172D",
                    }}
                  >
                    {ratio?.chgValue}
                  </Typography>
                  <Typography sx={{ fontSize: ".9vw" }}>SELLERS</Typography>
                </Box>
                <Box sx={{ width: "90%", px: 4 }}>
                  <LinearProgress
                    variant="determinate"
                    value={progress}
                    style={progressStyle}
                    sx={{
                      "& .MuiLinearProgress-bar": barStyle,
                    }}
                  />
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    width: "100%",
                    px: 7,
                    py: 1,
                    boxSizing: "border-box",
                  }}
                >
                  <Typography sx={{ fontSize: ".9vw", color: "#2FB545" }}>
                    {ratio?.Buyers}
                  </Typography>
                  <Box sx={{ mt: 1 }}>
                    <a href="https://www.bullionstats.com/">
                      <img
                        className={classes.bullionStatsImg}
                        src={bullionStats}
                        alt="bullionStats"
                      />
                    </a>
                  </Box>
                  <Typography sx={{ fontSize: ".9vw", color: "#D1172D" }}>
                    {100 - Number(ratio?.Buyers?.match(/\d+/)[0])}%
                  </Typography>
                </Box>
              </>
            </Box>
          </Box>
          <Box className={classes.rightPart}>
            <Box className={classes.header}>
              <Box
                sx={{
                  display: "flex",
                  color: "#fff",
                  alignItems: "start",
                  justifyContent: "center",
                  width: "25%",
                  flexDirection: "column",
                }}
              >
                <Box>
                  <Typography
                    sx={{
                      fontSize: "1.9vw",
                      letterSpacing: "2.2px",
                      display: "flex",
                      width: "100%",
                      justifyContent: "space-between",
                    }}
                  >
                    {formatday()
                      .split("")
                      .map((item, index) => (
                        <span key={index}>{item.toUpperCase()}</span>
                      ))}
                  </Typography>
                  <Typography
                    sx={{
                      fontSize: "1.8vw",
                      mt: -1,
                    }}
                  >
                    {formatDate().toUpperCase()}
                  </Typography>{" "}
                </Box>
              </Box>
              <Box
                sx={{
                  width: "50%",
                  height: "100%",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <img className={classes.logoImg} src={logo} alt="leenLogo" />
              </Box>

              <Box
                sx={{
                  display: "flex",
                  justifyContent: "end",
                  alignItems: "center",
                  width: "25%",
                  color: "#fff",
                }}
              >
                <Typography
                  sx={{
                    fontWeight: "bold",
                    fontSize: "2.5vw",
                  }}
                >
                  {formatTime(time)}
                </Typography>
              </Box>
            </Box>

            <Box className={classes.commoditieTable}>
              <Box className={classes.table}>
                <Box className={classes.tabeHeader}>
                  <Typography
                    sx={{
                      flex: 1,
                      display: "flex",
                      justifyContent: "center",
                      fontSize: "2vw",
                      fontWeight: "bold",
                    }}
                  >
                    COMMODITY
                  </Typography>
                  <Typography
                    sx={{
                      flex: 1,
                      display: "flex",
                      justifyContent: "center",
                      fontSize: "2vw",
                      fontWeight: "bold",
                    }}
                  >
                    WEIGHT
                  </Typography>
                  <Typography
                    sx={{
                      flex: 1,
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      fontSize: "2vw",
                      fontWeight: "bold",
                    }}
                  >
                    BUY
                    <Typography
                      sx={{
                        fontSize: "1vw",
                        ml: 0.5,
                        mt: 2,
                      }}
                    >
                      &nbsp;AED
                    </Typography>
                  </Typography>
                  <Typography
                    sx={{
                      flex: 1,
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      fontSize: "2vw",
                      fontWeight: "bold",
                    }}
                  >
                    SELL
                    <Typography
                      sx={{
                        fontSize: "1vw",
                        ml: 0.5,
                        mt: 2,
                      }}
                    >
                      &nbsp;AED
                    </Typography>
                  </Typography>
                </Box>
                <Box className={classes.tableContent}>
                  {commodities?.map((commodity, idx) => {
                    const words = commodity.commodity_title.split(" ");
                    return (
                      <Box
                        key={idx}
                        className={classes.tableRow1}
                        sx={{
                          maxHeight: commodities.length > 6 ? "17%" : "",
                        }}
                      >
                        <Typography
                          sx={{
                            fontSize: "1.3vw",
                            fontWeight: "bold",
                            justifyContent: "left",
                          }}
                          className={classes.tableRowColumn}
                        >
                          {words?.map((word, index) => {
                            if (index === 0) {
                              return (
                                <span
                                  key={index}
                                  style={{
                                    fontSize: "2vw",
                                    fontWeight: "bold",
                                    paddingLeft: "30px",
                                  }}
                                >
                                  {word.toUpperCase()}
                                </span>
                              );
                            } else {
                              return (
                                <span
                                  key={index}
                                  style={{
                                    fontSize: "1vw",
                                    marginTop: "0.2rem",
                                    fontWeight: "bold",
                                  }}
                                >
                                  &nbsp;{word.toUpperCase()}
                                </span>
                              );
                            }
                          })}
                          &nbsp;
                          {commodity.unitLabel === "TTB"
                            ? ""
                            : commodity.displayPurity}
                        </Typography>
                        <Box
                          className={classes.tableRowColumn}
                          sx={{
                            justifyContent: "left",
                          }}
                        >
                          <Typography
                            style={{ fontSize: "2vw", fontWeight: "bold" }}
                            sx={{
                              paddingLeft: { lg: "4.5vw", md: "3.5vw" },
                            }}
                          >
                            {commodity.unit} {commodity.unitLabel}
                          </Typography>
                        </Box>
                        <Box
                          className={classes.tableRowColumn}
                          sx={{
                            justifyContent: "left",
                          }}
                        >
                          <Typography
                            style={{
                              fontSize: "2vw",
                              fontWeight: "bold",
                            }}
                            sx={{
                              paddingLeft: { lg: "3.8vw", md: "3vw" },
                            }}
                          >
                            {commodityCalculation(
                              commodity.commodity_title === "Silver"
                                ? silver?.cur?.bid
                                : gold?.cur?.bid,
                              commodity.commodity_title === "Silver"
                                ? spread.silverBidSpread
                                : spread.goldBidSpread,
                              commodity.buy_premium,
                              3.674,
                              commodity.purity,
                              commodity.unit,
                              commodity.unitLabel === "TTB"
                                ? 116.64
                                : commodity.unitLabel === "KG"
                                ? 1000
                                : commodity.unitLabel === "OZ"
                                ? 31.1
                                : commodity.unitLabel === "TOLA"
                                ? 11.7
                                : 1,
                              commodity.buy_charge
                            )}
                          </Typography>
                        </Box>
                        <Box
                          className={classes.tableRowColumn}
                          sx={{
                            justifyContent: "left",
                          }}
                        >
                          <Typography
                            style={{ fontSize: "2vw", fontWeight: "bold" }}
                            sx={{
                              paddingLeft: { lg: "3.8vw", md: "3vw" },
                            }}
                          >
                            {commodityCalculation(
                              commodity.commodity_title === "Silver"
                                ? silver?.cur?.ask
                                : gold?.cur?.ask,
                              commodity.commodity_title === "Silver"
                                ? spread.silverAskSpread
                                : spread.goldAskSpread,
                              commodity.premium,
                              3.674,
                              commodity.purity,
                              commodity.unit,
                              commodity.unitLabel === "TTB"
                                ? 116.64
                                : commodity.unitLabel === "KG"
                                ? 1000
                                : commodity.unitLabel === "OZ"
                                ? 31.1
                                : commodity.unitLabel === "TOLA"
                                ? 11.7
                                : 1,
                              commodity.charges
                            )}
                          </Typography>
                        </Box>
                      </Box>
                    );
                  })}
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>
        <Box className={classes.updates}>
          <Box className={classes.updatesContent} sx={{ minHeight: "100%" }}>
            <Marquee
              speed={15}
              // delay={0}
              delay="2"
              style={{ height: "100%" }}
              gradient={false}
            >
              {news?.map((item, index) => {
                return (
                  <div
                    key={item._id}
                    style={{
                      fontSize: "1.3vw",
                      fontFamily: "poppins",
                      alignItems: "center",
                      display: "flex",
                    }}
                  >
                    <span style={{ fontWeight: "bold" }}>{item.newsTitle}</span>
                    &nbsp;&nbsp;:&nbsp;&nbsp;<span>{item.newsDetails}</span>
                    <span style={{ paddingRight: "50px", paddingLeft: "50px" }}>
                      {index === news.length - 1 ? "" : "|"}
                    </span>
                  </div>
                );
              })}
            </Marquee>
            <LogoutIcon
              sx={{ fontSize: "40px", color: "#FFFFFF", cursor: "pointer" }}
              onClick={handleLogout}
            />
          </Box>
        </Box>
      </Box>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={openlimitExpierd}
        closeAfterTransition
      >
        <Fade in={openlimitExpierd}>
          <Box>
            <LimitExceededModal logout={handleLogout} />
          </Box>
        </Fade>
      </Modal>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={openSubscription}
        onClose={handleClose}
        closeAfterTransition
      >
        <Fade in={openSubscription}>
          <Box>
            <SubscriptionExpiringSoon
              date={tv?.package.expire_date}
              handleClose={handleClose}
            />
          </Box>
        </Fade>
      </Modal>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={openSubscriptionExpierd}
        onClose={handleCloseSubscriptionExpierd}
        closeAfterTransition
      >
        <Fade in={openSubscriptionExpierd}>
          <Box>
            <SubscriptionExpired handleClose={handleLogout} />
          </Box>
        </Fade>
      </Modal>
    </div>
  );
};

export default Home;
